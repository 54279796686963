<template>
  <div class="login">
    <div class="main-login-page">
      <b-card class="loginPage">
        <h4 class="login-title">Ijodkorlar yagona portaliga xush kelibsiz</h4>
        <img class="oneid-img" src="@/assets/images/oneid.svg" alt="">
        <!-- <h4>One Id orqali kirish</h4> -->
        <!-- <img src="../../assets/images/login-back.jpg" alt=""> -->
        <b-button variant="primary" @click="SignbyOneId" class="enter_btn">OneID orqali kirish</b-button>
      </b-card>
    </div>
  </div>
</template>

<script>
import AccountService from "@/services/account.service";
import ApiService from "@/services/api.service";
import CreatorsService from '../../services/creators.sevice';
export default {
  data() {
    return {
      oneIdLoading: false
    }
  },
  created() {
    const queryString = window.location.search;
    const clientUrl = window.location.href.split("?")[0];
    const urlParams = new URLSearchParams(queryString);
    const OneIdCode = urlParams.get('OneIdCode')
    console.log(OneIdCode)
    if(!!OneIdCode){
      localStorage.setItem('OneIdCode',OneIdCode)
    }
    const OneIdRedirectUrl = urlParams.get('OneIdRedirectUrl')
    const localCode = localStorage.getItem('OneIdCode')
    if(localCode != "null" && localCode != null){
      this.oneIdLoading = true
      CreatorsService.GetList({ OneIdCode : localCode,OneIdRedirectUrl : clientUrl }).then(res => {
        this.oneIdLoading = false
        this.$i18n.locale = res.data.userinfo?.lang
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user_info", JSON.stringify(res.data.userinfo));
        this.$store.dispatch("auth/login", res.data);
        // if(res.data.token || res.data.token != 'fake'){
        //   ApiService.setHeader();
        // }
        
        this.$router.push(
          this.$route.query.redirectFrom || {
            path: "/",
          }
        );
      })
      .catch((error) => {
        this.oneIdLoading = false
        this.makeToast(error.response.data.error,'danger');
        console.log(error)
      });
    }
  },
  methods: {
    SignbyOneId(){
      window.location.replace(
        "https://sso.egov.uz/sso/oauth/Authorization.do?response_type=one_code&client_id=admin-qurilish_sport_uz&redirect_uri=" +
          this.$clientUrl + 
          "&scope=vakansiya.edu.uz&state=testState"
      );
    },
    makeToast(message, type) {
      this.$toast.open({
        message: message,
        type: type,
        duration: 5000,
        dismissible: true,
      }); 
    },
  },
}
</script>

<style lang="scss">
.login{
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/login-back2.jpg');
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
  background-size: cover;
}
.main-login-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .card {
    box-shadow: 0px 0px 20px 0px rgb(51 51 51 / 19%);
    border-radius: 13px !important;
    // .card-body {
    //   display: flex;
    //   justify-content: center;
    //   text-align: center;
    // }
  }
  .loginPage {
    width: 400px;
    height: 500px;
    text-align: center;
  }
  .oneid-img {
    margin-top: 15px;
    width: 300px;
    height: 310px;
  } 
  .login-title {
    text-align: center;
  }
  .enter_btn, .btn.btn-primary {
    background-color: #4825c2 !important;
  }
}

</style>