import ApiService from "./api.service";
const CreatorsService = {
    Get(id){
        return ApiService.get(`/Creators/Get?id=${id}`);
    },
    GetList(OneIdCode, OneIdRedirectUrl){
        return ApiService.get(`/Creators/GetList?OneIdCode=${OneIdCode}&OneIdRedirectUrl=${OneIdRedirectUrl}`)
    },
    GetAwardsList(){
        return ApiService.get(`/Creators/GetAwardsList`)
    },
    GetAwardedArtistsList(){
        return ApiService.get(`/Creators/GetAwardedArtistsList`)
    },
}
export default CreatorsService